#projects {
  padding-top: 1vh;
}
.projects-sub-head {
  width: 100%;
  border-bottom: 1px solid #0098dd;
  text-align: start;
  font-size: 1.5em;
  font-weight: bold;
}

.project-statements {
  margin: 2em;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-template-rows: repeat(1, 1fr);
  column-gap: 1rem;
  row-gap: 0;
  grid-auto-flow: dense;
}

.project-line {
  width: 10px;
}
.project-title {
  color: white;
  margin-top: 0;
  width: 100%;
  height: auto;
  background-color: #0097dd;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 5px;
}

.project-modal-expander {
  float: left;
  margin-top: 6px;
  margin-left: 6px;
  cursor: pointer;
}

.project-body {
  font-size: calc(max(1vw, 1.4vh));
  text-align: left;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}
.projects-grid > .project {
  border: 1px solid #0097dd;
  min-height: 200px;
}
.gen-art-container {
  width: 100%;
  height: 100%;
}

.gen-art-text {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  position: absolute;
}

.gen-art-title,
.game-title {
  text-decoration: underline;
  font-weight: bold;
}

.game-title {
  top: 5%;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}
.game-info {
  display: block;
  margin-left: 50%;
  transform: translate(-50%);
  width: 80%;
  text-align: justify;
}

.unbuild,
.ukiyo,
.furbar {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  max-width: 70%;
  max-height: 70%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  height: 70vh;
  width: 70vw;
  padding: 2rem;
}

.modal-children-container {
  width: 100%;
  height: 100%;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}
.next {
  right: 0;
  float: right;
  border-radius: 3px 0 0 3px;
  background-color: rgba(0, 0, 0, 0.2);
}

.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
  background-color: rgba(0, 0, 0, 0.2);
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 700px) {
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(100px, 0.9fr));
    grid-template-rows: max-content;
    grid-row-gap: 1rem;
    grid-auto-flow: dense;
  }
}
