@import url("./projects.css");

* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: "SourceCodePro";
  src: url(./assets/fonts/SourceCodePro-Regular.ttf);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes zoomin {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes slideLeft {
  0% {
    left: -500px;
  }
  100% {
    left: 50%;
    transform: translate(-50%);
  }
}

.fadein {
  animation: fadeIn 1s ease both;
}

.fadeout {
  animation: fadeOut 1s ease both;
}

.zoomin {
  animation: zoomin 1s ease both;
}

.slideleft {
  animation: slideLeft 1s ease both;
}

body {
  color: #fff;
  font-family: "SourceCodePro";
  font-size: 8pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  overflow-x: hidden;
  text-align: center;
}
.hidden {
  display: none;
}

.flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.center {
  margin: auto;
  width: 50%;
}
section {
  color: #616161;
  /* line-height: 11pt; */
  position: relative;
  min-height: 100vh;
}
section .container {
  box-sizing: border-box;
  margin: 0 auto 0;
  max-width: 1200px;
  padding: 0 10px;
  width: 80%;
}

.circle {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
}

/* nav tower css */
.nav {
  position: fixed;
  display: inline-block;
  right: -130px;
  height: 50%;
  transform: translate(0, 70%);
  z-index: 10;
}

.nav-dots {
  list-style-type: none;
}

.nav-dot {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  cursor: pointer;
}
.nav-section {
  cursor: pointer;
}
.nav-dots > li {
  transform: rotate(-40deg);
  text-align: left;
  height: 2em;
  line-height: 2em;
}
.nav-dots > li > a {
  color: #0098dd;
  height: 100%;
}

.nav-dots > li a.active {
  /* background-color: #0098dd; */
  /* text-decoration: underline; */
  color: white !important;
  overflow: hidden;
  display: inline-block;
  width: 200px;
  background-color: #0098dd;
  padding-left: 1em;
}
/* jpg prof setting */

/* landing page css */
#landing {
  padding: 0px !important;
  height: 100vh;
}
#landing .container {
  width: 60%;
  /* padding-top: 10%; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.about-head,
.projects-head {
  /* background-color: lightgray; */
  height: 10vh;
}

.about-head h1,
.projects-head h1 {
  font-size: 4em;
  text-decoration: underline;
}

.landing-head {
  /* opacity: 0.6; */
  font-size: 50pt;
  color: #275fe4;
}
.landing-head p {
  margin: 0;
}
.landing-info {
  font-size: 10pt;
  color: #c5a332; /*rgba(28, 64, 139, 0.8);*/
  width: 70%;
  padding-bottom: 1em;
}
.no-listyle {
  list-style-type: none;
}

/* pico 8 style */

.pico-8-tv {
  /* background-image: url(./assets/pico8tv.png); */
  padding: 10px;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
}

.pico-8-game {
  width: min(50vw, 50vh);
  height: min(50vw, 50vh);
}
.game-select-container {
  position: absolute;
  bottom: 0px;
}

/* icon classes */
.landing-links {
  list-style-type: none;
  padding-left: 0;
}

.landing-links > li {
  float: left;
  width: 18px;
  height: auto;
}

.landing-links li + li {
  margin-left: 3vw;
}

.landing-links path {
  fill: #a0a1a7;
}

.more-line-height {
  line-height: 10pt;
}

.button {
  border: 1px solid #a0a1a7;
  width: 70px;
  cursor: pointer;
  z-index: 10;
  margin-top: 1em;
}
.abpic-con {
  width: 30vw;
  height: 30vw;
  float: left;
  font-size: 1.5em;
  text-align: left;
  padding-top: ;
}
.abpic-con h1 {
  font-size: 4em;
  width: 100%;
  margin-top: 10vh;
  text-align: left;
}
/* http://www.fillmurray.com/200/200 */
.about-pic {
  margin-top: 10vh;
  margin-right: 20px;
  margin-bottom: 20px;
  background: url(./assets/images/richard-profile.png) 50% 0 no-repeat; /* 50% 50% centers image in div */
  background-size: 200px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  float: left;
  border: 4px solid#0097dd;
}
.about-text {
  width: 100px;
  height: 100px;
  border: 1px solid blue;
}

.wheel-house {
  display: flex;
  flex-direction: row !important;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
}

.skicon-con {
  height: auto;
  width: auto;
}

.skills-icons {
  cursor: pointer;
  position: relative;
  font-size: 48px;
  height: 30vw;
  width: 30vw;
  /* left: 90%;
  transform: translate(-100%); */
  border-radius: 50%;
  /* box-shadow: 0px 0px 10px 0px #0098dd; */
}
.skill-icon {
  cursor: default;
  background-color: #ffffff;
}

.mobile-skills-icons {
  display: none;
}

.skills-text {
  left: 50%;
  top: 50%;
  width: 300px;
  transform: translate(-50%, -50%);
  font-size: 0.5em;
  position: absolute;
}

.skills-icons a {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1em;
  margin: -0.5em;
}

.skill-bars {
  width: 140px;
  position: absolute;
  height: 40px;
  top: 20px;
  left: 50px;
}
.skill-bars-left {
  width: 140px;
  position: absolute;
  height: 40px;
  top: 20px;
  left: -150px;
}
.skill-bars p,
.skill-bars-left p {
  position: absolute;
  width: 100%;
  height: 15px;
  border: 1px solid #0098dd;
  font-size: 0.25em;
  color: white;
  text-align: start;
  /* opacity: 0; */
}

.deg0 {
  transform: translate(10vw);
  transform: translate(10vw);
} /* 10vw = half the width of the wrapper */

.deg36 {
  transform: rotate(36deg) translate(10vw) rotate(-36deg);
}

.deg72 {
  transform: rotate(72deg) translate(10vw) rotate(-72deg);
}

.deg108 {
  transform: rotate(108deg) translate(10vw) rotate(-108deg);
}

.deg144 {
  transform: rotate(144deg) translate(10vw) rotate(-144deg);
}

.deg180 {
  transform: translate(-10vw);
}

.deg216 {
  transform: rotate(216deg) translate(10vw) rotate(-216deg);
}

.deg252 {
  transform: rotate(252deg) translate(10vw) rotate(-252deg);
}

.deg288 {
  transform: rotate(288deg) translate(10vw) rotate(-288deg);
}

.deg324 {
  transform: rotate(324deg) translate(10vw) rotate(-324deg);
}

.mdi-language-javascript {
  color: #f7df1e;
}
.mdi-language-css3 {
  color: #264de4;
}
.mdi-language-html5 {
  color: #e44d26;
}
.mdi-language-java {
  color: #de0a17;
}
.mdi-react {
  color: #61dafb;
}
.custom-icon-container {
  position: relative;
}
.mdi-database {
  color: #4ab9d8;
}
.sql-logo {
  position: absolute;
  font-size: 10px;
  font-weight: bold;
  color: white;
  left: 50%;
  top: 13px;
  transform: translate(-50%);
}
.mdi-github {
  color: black;
}
.mdi-nodejs {
  color: #689f63;
}
.mdi-npm {
  color: #cb3837;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
}
.grid > .item {
  border: 1px solid #0097dd;
  height: 200px;
  width: 200px;
}

.item-line {
  width: 10px;
}
.item-title {
  color: white;
  margin-top: 0;
  width: 100%;
  height: auto;
  background-color: #0097dd;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 5px;
}

.item-body {
  font-size: 1.5em;
  text-align: left;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

#light {
  color: #f9f9f9;
  color: #383a42;
  color: #f31459;
  color: #f1f1f1;
  color: #0098dd;
  color: #a0a1a7;
  color: #7a82da;
  color: #23974a;
  color: #d52753;
  color: #c5a332;
  color: #ce33c0;
  color: #823ff1;
  color: #df631c;
  color: #275fe4;
  color: #a05a48;
  color: #d2ecff;
  color: #ff0000;
}

/* REACT PDF */
.react-pdf__Page__canvas {
  margin: auto;
}

@media only screen and (max-height: 500px) {
}

@media only screen and (max-width: 1100px) {
  .nav {
    right: -130px;
  }

  .nav-dots > li {
    transform: rotate(-45deg);
    text-align: left;
    height: 2em;
    line-height: 2em;
  }

  .abpic-con {
    width: auto;
    height: auto;
  }

  .mobile-skills-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    position: relative;
    font-size: 48px;
    /* width: 200px; */
    height: 100%;
  }

  .mobile-skills-icon {
    position: relative;
    padding-bottom: 48px;
  }

  .mobile-skills-icons i {
    float: left;
    position: absolute;
    left: 50%;
    transform: translate(-250%);
  }

  .mobile-skill-bars {
    width: 140px;
    position: absolute;
    height: 40px;
    top: 20px;
    left: 50%;
    transform: translate(-30%);
  }
  .mobile-skill-bars p {
    border: 1px solid #0098dd;
    font-size: 0.25em;
    color: white;
    text-align: start;
    height: 16px !important;
  }
  .mobile-skill-bars div {
    height: 17px !important;
  }
  .skills-icons {
    display: none;
  }
  /* REACT PDF */
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
}
